import { catchError, map, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { ProfilePersonService } from '@eros-api/services/profile-person.service';

import { formDataActions } from '../actions';
import { AuthService } from '@app/libs/auth/data-access';

@Injectable()
export class FormDataEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private profilePersonService: ProfilePersonService
  ) {}

  ngrxOnInitEffects(): Action {
    if (this.authService.getAccessToken()) {
      return formDataActions.loadFormData();
    }

    return { type: '' };
  }

  loadFormsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formDataActions.loadFormData),
      switchMap(() => this.profilePersonService.getFormData()),
      map((formData) => formDataActions.loadFormDataSuccess({ formData })),
      catchError(({ error }: HttpErrorResponse) =>
        of(formDataActions.loadFormDataFailure({ error }))
      )
    )
  );
}
